/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useRef, useState } from "react";
import tw, { css, theme } from "twin.macro";
import { graphql, navigate } from "gatsby";
import { colors } from "tailwindcss/defaultTheme";
import { useApp, useAuth, useTheme, useWindowDimensions } from "~hooks";
import { Go } from "~components";
import {
  Button,
  Footer,
  Image,
  InfiniteScrollLoop,
  Layout,
  NewsletterForm
} from "~components";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as AttributeEco } from "~assets/icons/tree-icon.svg";
import { ReactComponent as AttributeShare } from "~assets/icons/cultivate-icon.svg";
import { ReactComponent as AttributeGrow } from "~assets/icons/leaves-icon.svg";
import { ReactComponent as AttributeLandscape } from "~assets/icons/landscape-icon.svg";
import { ReactComponent as AttributeSupport } from "~assets/icons/replenish-icon.svg";
import { ReactComponent as AttributeTools } from "~assets/icons/tools-icon.svg";
import { ReactComponent as TheWIP } from "~assets/icons/the-wip.svg";

const IndexPage = ({ data, location }) => {
  // ===========================================================================
  // context / ref / state

  const { setNewsletterActive, setMenuActive, setRegisterActive } = useApp();
  const { colours, colourTransitionCSS } = useTheme();

  const fixedContentRef = useRef();

  const [expandedSection, setExpandedSection] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  // ===========================================================================
  // variables

  const { sanityIndexPage } = data;

  const windowHeight = useWindowDimensions().height;

  const defaultBackground = `
  ${colourTransitionCSS};
    background: ${colours.background};
    color: ${colours.foreground};
  `;

  const inverseBackground = `
    ${colourTransitionCSS};
    background: ${colours.foreground};
    color: ${colours.background};
  `;

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (!fixedContentRef?.current) {
      return;
    }

    setScrollPosition(
      0 - fixedContentRef.current.getBoundingClientRect().height + windowHeight
    );
  }, [fixedContentRef]);

  // ===========================================================================
  // content bailout

  // if (!indexPage?.[0]) {
  //   return <></>;
  // }

  let questions;

  if (sanityIndexPage?.questions) {
    ({ questions } = sanityIndexPage);
  }

  // ===========================================================================
  // variables

  const blockHeights = [`55vw`, `46.25vw`, `54vw`, `45vw`, `70vw`, `45vw`];

  const platformIconCSS = `
    height: 2rem;

    @media screen and (min-width: 1025px) {
      height: 2.5vw;
    }
  `;

  //
  // sections

  const sections = [
    <div
      css={[
        css`
          ${defaultBackground};

          padding: 7rem 0.75rem 2rem;

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[0]};
            padding: 2rem 8% 0;
          }
        `,
        tw`w-full relative flex flex-col items-center justify-center`
      ]}
    >
      <div
        css={[
          css`
            //
          `,
          tw`w-full relative flex items-center`
        ]}
      >
        <TheWIP
          css={[
            css`
              height: 2.125rem;
              margin-top: -0.45rem;
              margin-right: 0.75rem;

              ${MEDIA_QUERIES.desktop} {
                width: 17vw;
                height: auto;
                margin-top: -1vw;
                margin-right: 2vw;
              }
            `,
            tw`relative block`
          ]}
          fill={colours.foreground}
        />

        <h1 css={[tw`text-h1 md:text-h1-md font-head`]}>
          {sanityIndexPage.headingUpper}
        </h1>
      </div>

      <div
        css={[
          css`
            //
          `,
          tw`w-full relative`
        ]}
      >
        <span
          css={[tw`text-h1 md:text-h1-md font-head`]}
          dangerouslySetInnerHTML={{ __html: sanityIndexPage.headingLower }}
        />
      </div>
    </div>,
    <div
      css={[
        css`
          ${defaultBackground};

          padding: 0 0.75rem 4rem;

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[0]};
            padding: 0;
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <div css={[tw`w-full md:w-2/3 relative mt-6`]}>
        <p css={[tw`text-b2 md:text-b2-md font-body`]}>
          <span dangerouslySetInnerHTML={{ __html: sanityIndexPage.intro }} />
        </p>

        <Button
          css={[
            css`
              padding: 0.5rem 4rem;
              margin-top: 2rem;
              pointer-events: auto;
            `,
            tw`relative block`
          ]}
          onClick={() => {
            setMenuActive(false);
            setRegisterActive(true);
          }}
          color="green-dark"
          text="Sign Up"
        />
      </div>
    </div>,

    <div
      css={[
        css`
          @media screen and (min-width: 1025px) {
            height: ${blockHeights[1]};
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <Image
        _css={[tw`w-full h-full relative block object-cover`]}
        image={sanityIndexPage?.introImage}
        fallbackCSS="width: 66%"
      />
    </div>,
    <div
      css={[
        css`
          ${inverseBackground};

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[1]};
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <article
        css={[
          css`
            padding: 3rem 0.75rem 4rem;

            @media screen and (min-width: 1025px) {
              padding: 0 7.25% 0 0;
            }
          `,
          tw`w-full md:w-3/5 relative text-left`
        ]}
      >
        <p
          css={[
            css`
              margin-bottom: 1.75vw;
            `,
            tw`text-b1 md:text-b1-md font-inline`
          ]}
        >
          <span
            dangerouslySetInnerHTML={{ __html: sanityIndexPage.missionHeading }}
          />
        </p>
        <p css={[tw`text-b1 md:text-b1-md font-body`]}>
          <span
            dangerouslySetInnerHTML={{ __html: sanityIndexPage.missionBody }}
          />
        </p>
      </article>
    </div>,

    <div
      css={[
        css`
          ${inverseBackground};

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[2]};
            padding-top: 12%;
          }
        `,
        tw`w-full relative flex items-start justify-center`
      ]}
    >
      <article
        css={[
          css`
            padding: 3rem 0.75rem 4rem;

            @media screen and (min-width: 1025px) {
              padding: 3% 0 7.25% 0;
            }
          `,
          tw`w-full md:w-3/5 relative text-left`
        ]}
      >
        <p
          css={[
            css`
              margin-bottom: 1.75vw;
            `,
            tw`text-b1 md:text-b1-md font-inline`
          ]}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: sanityIndexPage.platformHeading
            }}
          />
        </p>
        <p css={[tw`text-b1 md:text-b1-md font-body`]}>
          <span
            dangerouslySetInnerHTML={{ __html: sanityIndexPage.platformBody }}
          />
        </p>
      </article>
    </div>,

    <div
      css={[
        css`
          ${defaultBackground};

          padding: 4rem 0.75rem 4rem;

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[2]};
            padding: 0;
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <ul
        css={[
          tw`w-full md:w-3/5 relative flex flex-col items-center justify-center`
        ]}
      >
        {sanityIndexPage.attributes.map(({ icon, text }) => {
          let attributeIcon;

          switch (icon) {
            case `AttributeEco`:
              attributeIcon = (
                <AttributeEco
                  css={[
                    css`
                      ${platformIconCSS}
                    `
                  ]}
                  fill={colours.background}
                />
              );

              break;

            case `AttributeTools`:
              attributeIcon = (
                <AttributeTools
                  css={[
                    css`
                      ${platformIconCSS}
                    `
                  ]}
                  fill={colours.background}
                />
              );

              break;

            case `AttributeShare`:
              attributeIcon = (
                <AttributeShare
                  css={[
                    css`
                      ${platformIconCSS}
                    `
                  ]}
                  fill={colours.background}
                />
              );

              break;

            case `AttributeGrow`:
              attributeIcon = (
                <AttributeGrow
                  css={[
                    css`
                      ${platformIconCSS}
                    `
                  ]}
                  fill={colours.background}
                />
              );

              break;

            case `AttributeLandscape`:
              attributeIcon = (
                <AttributeLandscape
                  css={[
                    css`
                      ${platformIconCSS}
                    `
                  ]}
                  fill={colours.background}
                />
              );

              break;

            case `AttributeSupport`:
              attributeIcon = (
                <AttributeSupport
                  css={[
                    css`
                      ${platformIconCSS}
                    `
                  ]}
                  fill={colours.background}
                />
              );

              break;

            default:
              break;
          }

          return (
            <li
              css={[
                css`
                  margin-bottom: 2rem;

                  @media screen and (min-width: 1025px) {
                    margin-bottom: 6%;
                  }
                `,
                tw`w-full relative flex items-center justify-between md:justify-center`
              ]}
            >
              <div
                css={[
                  css`
                    width: 4rem;
                    height: 4rem;
                    border-radius: 25%;
                    margin-right: 0.5rem;

                    background: ${colours.foreground};

                    @media screen and (min-width: 1025px) {
                      width: 5vw;
                      height: 5vw;
                      margin-right: 6%;
                    }
                  `,
                  tw`relative flex items-center justify-center`
                ]}
              >
                {attributeIcon}
              </div>

              <p
                css={[
                  css`
                    width: calc(100% - 6rem);

                    @media screen and (min-width: 1025px) {
                      width: 80%;
                    }
                  `,
                  tw`relative block text-b2 md:text-b2-md font-body`
                ]}
              >
                {text}
              </p>
            </li>
          );
        })}
      </ul>
    </div>,

    <div
      css={[
        css`
          @media screen and (min-width: 1025px) {
            height: ${blockHeights[3]};
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <Image
        _css={[tw`w-full h-full relative block object-cover`]}
        image={sanityIndexPage?.quoteImage}
        fallbackCSS="width: 66%"
      />
    </div>,

    <div
      css={[
        css`
          ${inverseBackground};

          padding: 4rem 0.75rem 3rem;

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[3]};
            padding: 0;
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <article
        css={[
          css`
            @media screen and (min-width: 1025px) {
              padding-right: 7%;
            }
          `,
          tw`w-full md:w-3/5 relative text-left`
        ]}
      >
        <p css={[tw`text-b1 md:text-b1-md font-body`]}>
          <span
            dangerouslySetInnerHTML={{ __html: sanityIndexPage.quoteBody }}
          />
        </p>

        <p
          _css={[
            css`
              margin-top: 1.5rem;

              @media screen and (min-width: 1025px) {
                margin-top: 7%;
              }
            `,
            tw`text-b1 md:text-b1-md font-body`
          ]}
        >
          <span
            dangerouslySetInnerHTML={{ __html: sanityIndexPage.quoteAuthor }}
          />
        </p>
      </article>
    </div>,

    <div
      css={[
        css`
          ${inverseBackground};

          padding: 3rem 0.75rem 3rem;

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[4]};
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <article css={[tw`w-full md:w-2/3 relative block`]}>
        <p
          css={[
            css`
              margin-bottom: 1.75vw;
            `,
            tw`text-b1 md:text-b1-md font-inline`
          ]}
        >
          THINGS TO KNOW
        </p>

        <ul css={[tw`w-full relative block`]}>
          {questions.map((question, questionIndex) => {
            const expanded = expandedSection === questionIndex;

            return (
              <li>
                <button
                  type="button"
                  css={[
                    css`
                      padding: 6% 0 5%;
                    `,
                    tw`w-full relative block border-green-dark border-opacity-30 border-b text-left`
                  ]}
                  onClick={() =>
                    setExpandedSection(expanded ? null : questionIndex)
                  }
                >
                  <div
                    css={[
                      tw`w-full relative flex items-center justify-between`
                    ]}
                  >
                    <p
                      css={[
                        tw`w-5/6 relative block text-b2 md:text-b2-md font-body`
                      ]}
                    >
                      <em>{question.question}</em>
                    </p>

                    <div
                      css={[
                        css`
                          width: 2rem;
                          height: 2rem;

                          background: ${expanded ? colors.background : `white`};
                          color: ${expanded ? `white` : colors.background};

                          @media screen and (min-width: 1025px) {
                            width: 2vw;
                            height: 2vw;
                          }

                          @media not all and (pointer: coarse) {
                            &:hover {
                              background: ${colors.background};
                              color: white;
                            }
                          }
                        `,
                        tw`flex items-center justify-center rounded-full`
                      ]}
                    >
                      <p css={[tw`text-b1 md:text-b1-md font-body`]}>
                        {expanded ? `-` : `+`}
                      </p>
                    </div>
                  </div>
                </button>

                {expanded && (
                  <>
                    <p
                      _css={[
                        css`
                          padding: 6% 0;
                        `,
                        tw`hidden md:block text-b1 md:text-b1-md font-body`
                      ]}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: question.answer }}
                      />
                    </p>

                    {/* <p
                      _css={[
                        css`
                          padding: 1rem 0;
                        `,
                        tw`md:hidden text-b1-md font-body`
                      ]}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: question.answer }}
                      />
                    </p> */}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </article>
    </div>,

    <div
      css={[
        css`
          ${defaultBackground};

          padding: 7rem 1rem;

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[4]};
            padding: 0 8%;
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <Image
        _css={[tw`w-full relative block`]}
        image={sanityIndexPage?.appImage}
        fallbackCSS="width: 66%"
      />
    </div>,

    <div
      id="newsletter"
      css={[
        css`
          ${defaultBackground};

          padding: 3rem 1rem 2rem;

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[5]};
            padding: 0 8%;
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <h2 css={[tw`text-h2 md:text-h2-md font-head`]}>
        <span dangerouslySetInnerHTML={{ __html: sanityIndexPage.footnote }} />
      </h2>
    </div>,

    <div
      css={[
        css`
          ${inverseBackground};

          @media screen and (min-width: 1025px) {
            height: ${blockHeights[5]};
          }
        `,
        tw`w-full relative flex items-center justify-center`
      ]}
    >
      <figure
        css={[
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center`
        ]}
      >
        <Image
          _css={[tw`w-full h-full relative block object-cover`]}
          image={sanityIndexPage?.newsletterImage}
          fallbackCSS="width: 66%"
        />
      </figure>

      <NewsletterForm prefix="0" />
    </div>
  ];

  const contentLeft = sections.map((section, sectionIndex) => {
    if (sectionIndex % 2 !== 0) {
      return <></>;
    }

    return (
      <div css={[tw`w-full relative flex`]}>
        <div css={[tw`w-1/2 relative`]}>{section}</div>
        <div css={[tw`w-1/2 relative invisible`]} />
      </div>
    );
  });

  //
  // content

  const contentRight = (
    <>
      {sections
        .slice(0)
        .reverse()
        .map((section, sectionIndex) => {
          if (sectionIndex % 2 !== 0) {
            return <></>;
          }

          return section;
        })}
    </>
  );

  const desktopContent = (
    <div css={[tw`hidden md:block`]}>
      <div css={[tw`w-full relative`]}>
        <InfiniteScrollLoop
          render={({ height, scrollTop }) => {
            if (!fixedContentRef?.current) {
              return;
            }

            let adjustedScrollTop = scrollTop;

            if (scrollTop === 0) {
              adjustedScrollTop = height * 2;
            }

            const normalisedScrollPosition = adjustedScrollTop - height * 2;
            const rightColumnContentHeight =
              fixedContentRef.current.getBoundingClientRect().height;

            setScrollPosition(
              normalisedScrollPosition - rightColumnContentHeight + windowHeight
            );
          }}
        >
          {contentLeft}
        </InfiniteScrollLoop>
      </div>

      <section
        css={[
          tw`w-1/2 h-screen fixed top-0 right-0 bottom-0 z-10 overflow-hidden pointer-events-none bg-green-dark`
        ]}
      >
        <div
          ref={fixedContentRef}
          css={[
            css`
              transform: translate3d(0, ${scrollPosition}px, 0);
            `,
            tw`w-full relative block`
          ]}
        >
          {contentRight}
          {contentRight}
        </div>
      </section>
    </div>
  );

  const getTopicJSX = (key) => {
    const wrapperKey = `topic-wrap-${key}`;

    return (
      <div
        key={wrapperKey}
        className="topic-list"
        css={[
          css`
            animation: 20s marquee linear infinite;
            transform: translate3d(50%, 0, 0);
            flex: 1 0 auto;
          `,
          tw`relative flex items-center justify-center flex-nowrap whitespace-nowrap`
        ]}
      >
        {sanityIndexPage?.bannerLinks.map((topic) => {
          const key = `topic-marquee-${topic?.text}`;
          const innerJSX = (
            <Topic
              colour={
                sanityIndexPage?.bannerTextColour?.hex ?? colours.foreground
              }
              text={topic?.text}
            />
          );

          return (
            <>
              {(topic?.url && (
                <Go to={topic.url} key={key} newTab>
                  {innerJSX}
                </Go>
              )) || <span key={key}>{innerJSX}</span>}
            </>
          );
        })}
      </div>
    );
  };

  const Topic = ({ colour, text }) => (
    <div
      css={[
        css`
          flex: 0 1 auto;
          color: ${colour};

          ${MEDIA_QUERIES.hoverable} {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        `,
        tw`relative mr-12 flex items-center justify-center`
      ]}
    >
      <div
        css={[tw`w-6 h-6 relative block mr-6 rounded-full overflow-hidden`]}
      />

      <h4 css={[tw`relative block font-head text-h3 md:text-h3-md`]}>{text}</h4>
    </div>
  );

  const banner = (
    <div
      css={[
        css`
          background-color: ${sanityIndexPage?.bannerColour?.hex ?? "#24594c"};
        `,
        tw`w-full pt-16 md:pt-24 fixed z-40`
      ]}
    >
      <div
        css={[
          css`
            @keyframes marquee {
              0% {
                transform: translate3d(50%, 0, 0);
              }
              100% {
                transform: translate3d(-50%, 0, 0);
              }
            }
          `,
          tw`w-full relative block pb-4 overflow-hidden`
        ]}
      >
        <div
          css={[
            css`
              &:hover {
                .topic-list {
                  animation-play-state: paused;
                }
              }
            `,
            tw`w-full relative flex items-center justify-center flex-nowrap`
          ]}
        >
          {Array(4)
            .fill(null)
            .map((_, topicIndex) => {
              const key = `topic-group-${topicIndex}`;

              return <div key={key}>{getTopicJSX(key)}</div>;
            })}
        </div>
      </div>
    </div>
  );

  //
  // position section swap haxx

  const swap1 = sections[2];
  // eslint-disable-next-line prefer-destructuring
  sections[2] = sections[3];
  sections[3] = swap1;

  const swap2 = sections[8];
  // eslint-disable-next-line prefer-destructuring
  sections[8] = sections[9];
  sections[9] = swap2;

  const mobileContent = (
    <div css={[tw`md:hidden`]}>{sections.map((section) => section)}</div>
  );

  // ==========================================================================
  // render

  return (
    <>
      {/* <SEO customTitle="Home" path={location.pathname} /> */}

      <Layout>
        {banner}
        {desktopContent}
        {mobileContent}
      </Layout>

      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    sanityIndexPage {
      title
      headingUpper
      headingLower
      intro
      missionHeading
      missionBody
      platformHeading
      platformBody
      quoteBody
      quoteAuthor
      footnote

      introImage {
        asset {
          gatsbyImageData(width: 1200, placeholder: NONE)
          altText
        }
      }

      quoteImage {
        asset {
          gatsbyImageData(width: 1200, placeholder: NONE)
          altText
        }
      }

      appImage {
        asset {
          gatsbyImageData(width: 1200, placeholder: NONE)
          altText
        }
      }

      newsletterImage {
        asset {
          gatsbyImageData(width: 1200, placeholder: NONE)
          altText
        }
      }

      attributes {
        icon
        text
      }

      questions {
        question
        answer
      }

      bannerLinks {
        text
        url
      }
      bannerColour {
        title
        hex
      }
      bannerTextColour {
        title
        hex
      }

      seoDescription
      seoKeywords
    }
  }
`;
